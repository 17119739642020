.Join {
    display: flex;
    padding: 0 2rem;
    gap: 10rem;
}

.left-j{
    color: white;
    font-size: 3rem;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
}

.left-j>hr{
    position: absolute;
    width: 10.5rem;
    border: 2px solid var(--orange);
    border-radius: 20%;
    margin: -10px 0;
}

.left-j>div{
    display: flex;
    gap: 1rem;
}

.right-j {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    
   
 
}

.email-container{
    display: flex;
    gap: 2rem;
    background-color: rgb(128, 128, 128);
    padding: 1.3rem 1.2rem; 
    border-radius: 0.23rem;
    margin-top: -1rem;
    
}


.email-container>input{
    background-color: transparent;
    border: 2px solid rgb(255, 231, 196);
    outline: none ;
    color: var(--lightgrayz);
    padding: 0.23rem;
    
}

::placeholder {
    color: var(--lightgray);
    text-align: center;
}

.btn-j {
    background-color: var(--orange);
    color: whitesmoke;
}

@media screen and (max-width: 768px)
{

.Join{
    flex-direction: column;
    gap: 1rem;
    margin-top: -2rem;

}
.left-j{
    font-size: x-large;
    flex-direction: column;
}

.right-j{
    padding: 2rem;
}
}

